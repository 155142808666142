import React from 'react'
import { Layout, Seo } from '../components'
import styled from 'styled-components'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'
import { PrivacyPolicy as PrivacyPolicyContent } from '../sections'

const PrivacyPolicy = () => {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    return (
        <Layout>
            <Seo
                title={t('privacy_policy_seo_title')}
                description={t('privacy_policy_seo_description')}
            />
            <Wrapper>
                <PrivacyPolicyContent locale={intl.locale} />
            </Wrapper>
        </Layout>
    )
}

const Wrapper = styled.section`
    margin: 0 auto;
    padding: 160px 24px;
    width: 100%;
    max-width: 864px;
    h2 {
        font-size: 46px;
        line-height: 88px;
    }
    @media (max-width: 900px) {
        margin: 40px auto;
        h2 {
            font-size: 36px;
            line-height: 44px;
        }
    }
`

export default injectIntl(PrivacyPolicy)
